import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import RedAlert from '../ui-elements/RedAlert'
import { getDate } from '../utils/getDate'
import { getDateFromMonthYearDay } from '../utils/getDateFromMonthYearDay'
import Spinner from '../ui-elements/Spinner'
import FormatChecklistTimeWindow from './FormatChecklistTimeWindow'
import FormatQuestionAnsweredBy from './FormatQuestionAnsweredBy'
import YellowAlert from '../ui-elements/YellowAlert'
import Modal from '../ui-elements/Modal'
import { sortChecklistDataByTimePeriod } from '../utils/sortChecklistDataByTimePeriod'

type Props = {
}

type CheckPeriodProps = {
    thisCheckPeriod: ObjectStringKeyAnyValue
}

type CheckPeriodDetailsProps = {
    checklistName: string | null
    thisCheckPeriod: ObjectStringKeyAnyValue
    setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>
    answerNotes: ObjectStringKeyAnyValue | null
    answerAlerts: ObjectStringKeyAnyValue[] | null
}

function CheckPeriodDetails({
    checklistName,
    thisCheckPeriod,
    setShowDetailsModal,
    answerNotes,
    answerAlerts,
}: CheckPeriodDetailsProps) {

    const {
        tableData,
        currentOrganisation
    } = useContext(GlobalContext)

    return <Modal setShowModal={setShowDetailsModal} showCloseButton={true}>

        <div className={`flex flex-col gap-6`}>

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-3xl text-brandblue`}>{checklistName ? checklistName : `Checklist #${thisCheckPeriod.ChecklistId}`}</h3>

                <p className={`text-sm`}>
                    Check period:
                    <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />
                </p>
            </div>

            {/* <p className={`text-xs italic text-gray-600`}>{JSON.stringify(thisCheckPeriod)}</p> */}

            {thisCheckPeriod.Signoff && <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Checklist signed off</h3>
                <p className={`text-xs italic text-gray-600`}>Signed off on {getDate(thisCheckPeriod.Signoff.TimeStamp, 'formattedDateAndTime')} by <FormatQuestionAnsweredBy questionAnswer={thisCheckPeriod.Signoff} /></p>
                {thisCheckPeriod.Signoff.Signature && <img
                    src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisCheckPeriod.Signoff.Signature}`}
                    alt={`Signoff signature`}
                    className={`border border-gray-400 mt-4 max-w-64 max-h-64 rounded`}
                />}
            </div>}

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Alerts</h3>
                {thisCheckPeriod.alertsForCheckPeriod && thisCheckPeriod.alertsForCheckPeriod.length > 0 ?
                    <div className={`mt-2 flex flex-col gap-2`}>
                        {thisCheckPeriod.alertsForCheckPeriod.map((thisAlert: any, index: number) => {
                            const questionText = tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[thisAlert.QuestionId] && tableData.ChecklistQuestions[thisAlert.QuestionId].QuestionText
                            return <RedAlert key={index}>
                                <div className={`flex flex-col gap-2 items-center justify-center`}>
                                    <p className={`font-bold`}>{questionText ?
                                        questionText :
                                        `Question Id: ${thisAlert.QuestionId}`
                                    }:</p>
                                    <p className={`text-sm`}>{thisAlert.message}</p>
                                </div>
                            </RedAlert>
                        })}
                    </div> :
                    <p>✅ No alerts</p>
                }</div>

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Notes</h3>
                {thisCheckPeriod.notesForCheckPeriod && Object.keys(thisCheckPeriod.notesForCheckPeriod).length > 0 ?
                    <div className={`mt-2 flex flex-col gap-2`}>
                        {Object.keys(thisCheckPeriod.notesForCheckPeriod).map((noteId, index) => {
                            const thisNote = thisCheckPeriod.notesForCheckPeriod[noteId]
                            const questionText = tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[thisNote.QuestionId] && tableData.ChecklistQuestions[thisNote.QuestionId].QuestionText
                            return <YellowAlert key={index}>
                                <div className={`flex flex-col gap-2 items-center justify-center`}>

                                    <p className={`font-bold`}>{questionText ?
                                        questionText :
                                        `Question Id: ${thisNote.QuestionId}`
                                    }:</p>

                                    {thisNote.PhotoNoteFileName && typeof thisNote.PhotoNoteFileName === 'string' && <img
                                        src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisNote.PhotoNoteFileName}`}
                                        alt=''
                                        className={`rounded bg-gray-300 min-w-12 min-h-12 max-w-36 max-h-36`}
                                    />}
                                    {thisNote.AnswerNote && <p>"{thisNote.AnswerNote}"</p>}
                                    <p className={`text-xs italic text-gray-600`}>Added by <FormatQuestionAnsweredBy questionAnswer={thisNote} /> on  {getDate(thisNote.Timestamp, 'formattedDateAndTime')}</p>
                                </div>
                            </YellowAlert>
                        })}
                    </div> :
                    <p>✅ No notes</p>
                }</div>


            <Button
                text={`Close`}
                onClick={() => setShowDetailsModal(false)}
                variant={`primary`}
                size={`big`}
                fullwidth={false}
            />
        </div>
    </Modal>
}



function CheckPeriod({
    thisCheckPeriod
}: CheckPeriodProps) {

    const {
        tableData,
        currentOrganisation
    } = useContext(GlobalContext)

    const [answerNotes, setAnswerNotes] = useState<ObjectStringKeyAnyValue | null>(null)
    const [answerAlerts, setAnswerAlerts] = useState<Array<ObjectStringKeyAnyValue> | null>(null)
    const [showDetailsModal, setShowDetailsModal] = useState(false)


    const isChecklistComplete = thisCheckPeriod && thisCheckPeriod.numberOfAnswers > 0 && thisCheckPeriod.numberOfAnswers === thisCheckPeriod.numberOfCompletedAnswers ? true : false
    const checklistName = tableData && tableData.Checklists && thisCheckPeriod && thisCheckPeriod.ChecklistId && tableData.Checklists[thisCheckPeriod.ChecklistId] && tableData.Checklists[thisCheckPeriod.ChecklistId].ChecklistName
    const signoffRequired = tableData && tableData.Organisation && tableData.Organisation.ChecklistOptions && tableData.Organisation.ChecklistOptions.RequireSignoff

 



    // console.log(tableData['ChecklistPeriods'])


    return <div>
        {showDetailsModal && <CheckPeriodDetails
            thisCheckPeriod={thisCheckPeriod}
            checklistName={checklistName}
            setShowDetailsModal={setShowDetailsModal}
            answerNotes={answerNotes}
            answerAlerts={answerAlerts}
        />}

        <div
            className={`w-full flex flex-col justify-between items-start gap-1 px-2 py-1 rounded bg-gray-200 hover:opacity-80 cursor-pointer`}
            onClick={() => {
                setShowDetailsModal(true)
            }}
        >

            <div className={``}>
                <div className={`flex flex-col flex-wrap items-start`}>
                    <p className={`text-sm`}>{checklistName ? checklistName : `Checklist #${thisCheckPeriod.ChecklistId}`}</p>
                    <p className={`text-xs italic text-gray-600`}>
                        Check period:
                        <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />
                    </p>
                    {/* {thisCheckPeriod.Id} */}
                    {/* <p className={`text-xs italic text-gray-600`}>{thisCheckPeriod.Id}</p> */}
      

                    {thisCheckPeriod.InProgress === 'True' && !isChecklistComplete &&
                        <p className={`animate-pulse text-xs italic text-gray-600`}> 🚨 in progress</p>}

                    {thisCheckPeriod.InProgress === 'False' && !isChecklistComplete &&
                        <p className={`text-xs italic text-gray-600`}> ⛔️ incomplete</p>}

                    {isChecklistComplete && signoffRequired && !thisCheckPeriod.Signoff && <p className={`text-xs italic text-gray-600`}> 🚨 signoff required</p>}

                    {isChecklistComplete && signoffRequired && thisCheckPeriod.Signoff && <p className={`text-xs italic text-gray-600`}> ✅ complete & signed off</p>}

                    {isChecklistComplete && !signoffRequired && <p className={`text-xs italic text-gray-600`}> ✅ complete</p>}
                </div>
            </div>

            <div className={`flex flex-row gap-2`}>
                {thisCheckPeriod.alertsForCheckPeriod && thisCheckPeriod.alertsForCheckPeriod.length > 0 &&
                    <RedAlert size='small'> {thisCheckPeriod.alertsForCheckPeriod.length} alerts</RedAlert>}


                {thisCheckPeriod.notesForCheckPeriod && thisCheckPeriod.notesForCheckPeriod.length > 0 &&
                    <YellowAlert size='small'> {thisCheckPeriod.notesForCheckPeriod.length} notes</YellowAlert>}

             
            </div>
        </div>
    </div>
}


function DailyChecklistSummary({
}: Props) {

    const {
        socket,
        tableData,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const shortMonths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    const longMonths = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]
    const navigate = useNavigate()
    // const { year, month } = useParams()
    const [waitingForDataExport, setWaitingForDataExport] = useState(false)
    const [checklistPeriodsByDay, setChecklistPeriodsByDay] = useState<null | ObjectStringKeyAnyValue>(null)
    const [dateDropdownOptions, setDateDropdownOptions] = useState<null | Array<string>>(null)
    const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false)
    const [month, setMonth] = useState<string | null>(null)
    const [year, setYear] = useState<string | null>(null)


    useEffect(() => {
        const newDateDropdownOptions: string[] = []
        const nowDate = new Date()
        const startMonth = (nowDate.getMonth() + 1)
        const startYear = nowDate.getFullYear()
        for (let i = 0; i < 12; i++) {
            let adjustedMonth = startMonth - i
            let adjustedYear = startYear
            if (adjustedMonth < 1) {
                adjustedYear--
                adjustedMonth = adjustedMonth + 12
            }
            const dateString = `${adjustedYear}-${adjustedMonth}`
            newDateDropdownOptions.push(dateString)
        }
        setDateDropdownOptions(newDateDropdownOptions)
    }, [year, month])

    useEffect(() => {
        if (tableData) {

            if (!hasDataBeenFetched) {
                fetchData()
            }

            if (tableData['ChecklistPeriods']) {
                const newChecklistPeriodsByDay = sortChecklistDataByTimePeriod(tableData, month, year, undefined)
                setChecklistPeriodsByDay(newChecklistPeriodsByDay)
            }

        }
    }, [tableData, year, month])


    const changeMonth = (monthAndYear: string) => {
        setHasDataBeenFetched(false)
        const dateParts = monthAndYear.split('-')
        const month = dateParts[1]
        const year = dateParts[0]
        setMonth(month)
        setYear(year)
    }

    const fetchData = () => {
        const payload = JSON.stringify({
            "action": "audit",
            "auditAction": "fetchChecklistInfoByTimePeriod",
            "month": month,
            "year": year
        })
        console.log('🎣 fetching...')
        sendMessageToWebsocket(payload)
        setHasDataBeenFetched(true)
    }



    const exportData = () => {
        setWaitingForDataExport(true)
        //console.log('exporting data')
        const payloadObj: ObjectStringKeyAnyValue = {
            "action": "audit",
            "auditAction": "exportDailyChecklistSummary",
            "month": month,
            "year": year
        }
        const payload = JSON.stringify(payloadObj)
        //console.log(payload)
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("documentReadyForDownloadResponse", data => {
            setWaitingForDataExport(false)
            window.location.href = data.downloadUrl
            unsubscribe()
        })

    }



    // console.log(tableData)
    // console.log(checklistPeriodsByDay)

    return <div className={`w-full flex flex-col items-center`}>
        <div className={`w-full max-w-3xl flex flex-col gap-4 px-5`}>




            <h3 className={`md:px-5 font-righteous text-3xl text-white`}>
            Results diary:
                {` ${month ? longMonths[parseInt(month) - 1] : longMonths[new Date().getMonth()]} `}
                {` ${year || new Date().getFullYear()} `}
            </h3>
            <div className={`md:px-5 w-full flex md:flex-row flex-col gap-4 justify-between`}>
                <div className={`flex flex-row gap-2 items-center justify-center text-sm`}>
                    <p>Select month:</p>
                    <select
                        onChange={(e: any) => changeMonth(e.target.value)}
                        className={`rounded px-2 py-1 bg-gray-200 border-gray-400 text-gray-600 text-sm`}
                        value={`${year}-${month}`}>
                        {dateDropdownOptions?.map((dateOption, index) => {
                            const dateParts = dateOption.split('-')
                            const thisMonth = parseInt(dateParts[1]) - 1
                            const thisYear = dateParts[0]
                            return <option
                                key={index}
                                value={dateOption}>
                                {shortMonths[thisMonth]} - {thisYear}
                            </option>
                        })}
                    </select>
                </div>

                <div className={`w-full md:w-auto`}>
                    <Button
                        onClick={exportData}
                        text={'Export to PDF'}
                        variant={'secondary'}
                        fullwidth={true}
                    />
                    {/* {waitingForDataExport && <div className={`bg-brandmidblue  text-white font-bold py-1 px-4 rounded hover:opacity-90`}><Spinner size={'small'}><p>Exporting...</p></Spinner></div>} */}
                </div>
            </div>


            {!checklistPeriodsByDay && <Spinner>Loading...</Spinner>}

 


            <div className={`w-full flex flex-col gap-4 text-black`}>
                {checklistPeriodsByDay && Object.keys(checklistPeriodsByDay).length > 0 &&
                    <div className={`w-full flex md:flex-row flex-col flex-wrap gap-2 justify-center`}>
                        {Object.keys(checklistPeriodsByDay).sort((a, b) => Number(b) - Number(a)).map((day, index) => {
                            const checkPeriodsForThisDay = checklistPeriodsByDay[day]
                            return <Card key={index}>
                                <div className={`w-full md:w-48 flex flex-col gap-2`}>
                                    <h4 className={`font-righteous text-xl text-brandblue`}>
                                        {getDateFromMonthYearDay(day, month, year)}
                                    </h4>

                                    {(checkPeriodsForThisDay && Object.keys(checkPeriodsForThisDay).length > 0) ?
                                        <div key={index} className={`w-full flex flex-col gap-2`}>
                                            {Object.keys(checkPeriodsForThisDay).map((checkPeriodId: string, index: number) => {
                                                const thisCheckPeriod = checkPeriodsForThisDay[checkPeriodId]
                                                return <CheckPeriod key={index} thisCheckPeriod={thisCheckPeriod} />
                                            })}
                                        </div>
                                        :
                                        <p>No check periods</p>
                                    }
                                </div>
                            </Card>
                        })}

                    </div>}
            </div>
        </div>
    </div>

}
export default DailyChecklistSummary