import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from './GlobalContext'
import Button from './formfields/Button'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"

// utilities
import CloudformationOutputs from './CloudformationOutputs.json'
import { publish } from './utils/pubsub'
import brandstylesjson from './utils/brandstyles.json'
import SplashPage from './components/SplashPage'
import ListReadings from './components/ListReadings'
import Header from './components/Header'
import { useRef } from 'react'
import DailyChecklistSummary from './components/DailyChecklistSummary'


type ObjectAny = {
  [key: string]: any
}

function App() {

  const brandstyles: ObjectAny = brandstylesjson['default']
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const [tableData, setTableData] = useState<ObjectAny | null>(null)
  const tableDataRef = useRef<any>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const [auditorName, setAuditorName] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [qrCodeId, setQrCodeId] = useState<string | null>(null)

  useEffect(() => {
    setConnectionState('tryToConnect')
  }, [])

  useEffect(() => {
    tableDataRef.current = tableData
  }, [tableData])

  useEffect(() => {
    const windowPath = window.location.pathname.replaceAll('/', '')
    if (windowPath && windowPath !== 'listreadings') {
      setQrCodeId(windowPath)
      localStorage.setItem('qrCodeId', windowPath)
    } else if (localStorage.getItem('qrCodeId')) {
      setQrCodeId(localStorage.getItem('qrCodeId'))
    }
  }, [qrCodeId])

  useEffect(() => {
    switch (connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          console.log("🔌  socket connected")
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break
    }
  }, [connectionState])

  useEffect(() => {
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])

  useEffect(() => {
    if (!auditorName && localStorage.getItem('auditorName')) {
      setAuditorName(localStorage.getItem('auditorName'))
    }
  }, [auditorName])

  useEffect(() => {
    if (socket && qrCodeId) {
      fetchOrganisationFromQrCodeId()
    }
  }, [qrCodeId, socket])

  useEffect(() => {
    if (qrCodeId && auditorName) {
      // fetch table data if there is no tabledata object or if it doesn't contain the keys we need
      if (!tableData) {
        fetchAllTableData()
      } else if (
        !Object.keys(tableData).includes('Assets') ||
        !Object.keys(tableData).includes('Groups') ||
        !Object.keys(tableData).includes('AssetProfiles')) {
        fetchAllTableData()
      }
    }
  }, [qrCodeId, tableData, auditorName])


  const fetchOrganisationFromQrCodeId = () => {
    // console.log('Get org id from QR code')
    const payload = JSON.stringify({
      action: 'audit',
      auditAction: 'fetchOrganisationFromQrCodeId',
      qrCodeId: qrCodeId
    })
    sendMessageToWebsocket(payload)
  }

  const fetchAllTableData = () => {
    // console.log(`🎣 fetching all data`)
    let requestAssets = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "fetchMultiTableData",
      tableNames: ["Assets", "Groups", "AssetProfiles", "Checklists", "ChecklistQuestions"]
    })
    sendMessageToWebsocket(requestAssets)

    // let requestFridgeGroups = JSON.stringify({
    //   action: "fridgetemps",
    //   fridgeAction: "requestFridgeGroups"
    // })

    // sendMessageToWebsocket(requestFridgeGroups)
    // let requestCheckingTimes = JSON.stringify({
    //   action: "fridgetemps",
    //   fridgeAction: "requestCheckingTimes"
    // })
    // sendMessageToWebsocket(requestCheckingTimes)
  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      socket.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }

  const logOut = () => {
    setAuditorName(null)
    setCurrentOrganisation(null)
    setQrCodeId(null)
    localStorage.removeItem('auditorName')
    localStorage.removeItem('organisationId')
    localStorage.removeItem('qrCodeId')
  }


  const mergeTableData = (newData: ObjectAny) => {
    const oldTableData = tableDataRef.current
    if (oldTableData) {
      let mergedTableData = { ...oldTableData };
      Object.keys(newData).forEach(key => {
        // Directly assigning the value from newData, overriding the oldData value if key exists in both
        mergedTableData[key] = newData[key];
      });
      setTableData(mergedTableData)
    } else {
      setTableData(newData)
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          console.log(`💌 message received: ${action}`)
          publish(action, payload)

          switch (action) {

            case undefined:
              console.log(data)
              break

            case 'error':
              console.log(`🚫 ${payload}`)
              setError(payload)
              logOut()
              break


            case 'returnMultiTableData':
              mergeTableData(payload)
              break

            case 'auditSummaryGenerated':
              console.log(payload)
              if (payload.downloadUrl) {
                window.location.href = payload.downloadUrl
              }
              break

            case 'returnSingleTableData':
              const newKey = Object.keys(payload)[0]
              const newValue = payload[newKey]
              const newTableData = { ...tableDataRef.current }
              newTableData[newKey] = newValue
              setTableData(newTableData)
              if (newKey === 'Organisation') {
                setCurrentOrganisation(newValue['OrganisationId'])
                localStorage.setItem('organisationId', newValue['OrganisationId'])
              }
              break


            case 'auditorNameSaved':
              // handled by pub sub
              break

            default:
              break
          }
        }
      }
    }
  }


  // console.log(tableData)

  return <div className={`w-full h-screen bg-brandblue text-white flex flex-col items-center justify-start text-center`}>
    <GlobalContext.Provider value={{
      brandstyles,
      socket,
      sendMessageToWebsocket,
      currentOrganisation,
      setCurrentOrganisation,
      auditorName,
      setAuditorName,
      error,
      tableData,
      qrCodeId,
      setQrCodeId
    }}>

      <Router>

        <Header logOut={logOut} />

        <Routes>

          <Route path='/*'
            element={<SplashPage />} />

          {/* <Route
              path='/listreadings/'
              element={<ListReadings />} /> */}

          <Route
            path='/listreadings/'
            element={<DailyChecklistSummary />} />

          <Route
            path='/:qrCodeIdFromUrl'
            element={<SplashPage />} />



        </Routes>
      </Router>
    </GlobalContext.Provider>
  </div>
}

export default App
